import { Button, Typography } from "antd";
import Lottie from "lottie-react";
import React, { lazy } from "react";
import { useNavigate } from "react-router-dom";
import notFound from "../assets/animations/not-found.json";

const NotFound = ({ path }) => {
  const navigate = useNavigate();
  const goBack = () => {
    if (path) {
      navigate(path, { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        verticalAlign: "center",
        minHeight: "100vh",
        background:
          "radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(207, 228, 233, 1) 100%)",
      }}
    >
      <div
        style={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={1}>404</Typography.Title>
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Page you are looking for not available
        </Typography.Title>
        <div>
          <Lottie animationData={notFound} loop={true} />
        </div>
        <Button onClick={goBack}>Go Home</Button>
      </div>
    </div>
  );
};
export default NotFound;
