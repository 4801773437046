import { IconMoodSadDizzy } from "@tabler/icons-react";
import { Button, Typography } from "antd";
import React, { useEffect } from "react";

const ErrorComponent = ({ error, resetErrorBoundary }) => {
  const handleRefresh = () => {
    window.location.reload();
  };
  useEffect(() => {
    console.log(error.message);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        verticalAlign: "center",
        minHeight: "100vh",
        background:
          "radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(207, 228, 233, 1) 100%)",
      }}
    >
      <div
        style={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          <IconMoodSadDizzy /> Aww Snap Something Went Wrong!!!
        </Typography.Title>
        <Button type="primary" onClick={handleRefresh}>
          Try Again
        </Button>
        <div>{/* <Lottie animationData={noInternet} loop={true} /> */}</div>
      </div>
    </div>
  );
};
export default ErrorComponent;
