import { Input, Modal, message } from "antd";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { apiClient } from "../../services/http.helper";
import { business_id } from "../../storage";
import { errorNotification } from "../../utils/notification.helper";

const DukanNameModal = ({
  handleChange,
  open,
  businessDetails,
  fetchDetails,
}) => {
  const [businessId] = useAtom(business_id);
  const [dukanName, setDukanName] = useState(businessDetails.dukan_name);

  const updateBusiness = async () => {
    try {
      var obj = businessDetails;
      obj.dukan_name = dukanName;
      obj.business_id = businessId;
      let result = await apiClient.post("/api/web/business/update", obj);
      if (result.status) {
        message.success("Dukan Name updated Successfully!!!");
        handleChange();
        fetchDetails();
      } else {
        message.error("Error Occured", result.message);
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(obj),
        "Companydetails",
        JSON.stringify(error),
      );
    }
  };

  return (
    <Modal
      title={"Edit Dukan Name"}
      open={open}
      onCancel={handleChange}
      onOk={updateBusiness}
      width={500}
    >
      <Input
        value={dukanName}
        onChange={(e) => setDukanName(e.currentTarget.value)}
      />
    </Modal>
  );
};

export default DukanNameModal;
