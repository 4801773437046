import { Typography } from "antd";
import Lottie from "lottie-react";
import React from "react";
import noInternet from "../assets/animations/nointernet.json";

const NoInternet = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        verticalAlign: "center",
        minHeight: "100vh",
        background:
          "radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(207, 228, 233, 1) 100%)",
      }}
    >
      <div
        style={{
          width: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={4} style={{ textAlign: "center" }}>
          Internet Connection Lost!!!
        </Typography.Title>
        <div>
          <Lottie animationData={noInternet} loop={true} />
        </div>
      </div>
    </div>
  );
};
export default NoInternet;
