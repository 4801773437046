import { Button, notification } from "antd";
import React from "react";
import { apiClient } from "../services/http.helper";

const openNotification = (type, message, description) => {
  notification[type]({
    message,
    description,
  });
};

const openExceptionNotification = (payload, fileName, error) => {
  const key = new Date().getTime().toString();

  notification.error({
    key,
    message: "Oops Something went wrong!!!",
    btn: (
      <Button
        type="link"
        size="small"
        onClick={() => handleReportIssue(key, payload, fileName, error)}
      >
        Report Issue
      </Button>
    ),
    // onClose: () => handleCloseNotification(key),
  });
};

const handleReportIssue = (key, payload, fileName, error) => {
  console.log(fileName);
  console.log(error);
  handleReportIsuee(payload, fileName, error);
  handleCloseNotification(key);
};

const handleReportIsuee = async (payload, fileName, error) => {
  apiClient
    .post("api/web/common/report-problem", {
      payload: payload,
      fileName: fileName,
      problemDescription: `Issue in ${fileName} and error is ${error}`,
    })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

const handleCloseNotification = (key) => {
  // Close the notification with the specified key
  notification.destroy(key);
};

export const successNotification = (message, description) => {
  openNotification("success", message, description);
};

export const errorNotification = (message, fileName, error) => {
  openExceptionNotification(message, fileName, error);
};

export const infoNotification = (message, description) => {
  openNotification("info", message, description);
};

export const warningNotification = (message, description) => {
  openNotification("warning", message, description);
};
