import React, { lazy } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import DukaanLayout from "../layout/DukaanLayout";
import CreateCoupon from "../pages/DigitalDukan/CreateCoupon";
import DeliveryDetail from "../pages/DigitalDukan/DeliveryDetail";
import CreateOrder from "../pages/DigitalDukan/CreateOrder";
const RequestShipmentOnboarding = lazy(
  () =>
    import("../pages/DigitalDukan/ShipOrderComponent/RequestShipmentOnboarding")
);
const Unautorized = lazy(() => import("../pages/Unautorized"));
const MyDukaan = lazy(() => import("../pages/DigitalDukan/MyDukaan"));
const ShopSettingLayout = lazy(
  () => import("../pages/DigitalDukan/ShopSettingLayout")
);
const StoreDetails = lazy(() => import("../pages/DigitalDukan/StoreDetails"));
const DomainDetails = lazy(() => import("../pages/DigitalDukan/DomainDetails"));
const SeoDetails = lazy(() => import("../pages/DigitalDukan/SeoDetails"));
const ShopAnalytics = lazy(() => import("../pages/DigitalDukan/ShopAnalytics"));
const StoreTimings = lazy(() => import("../pages/DigitalDukan/StoreTimings"));
const ShopPages = lazy(() => import("../pages/DigitalDukan/ShopPages"));
const StorePayments = lazy(() => import("../pages/DigitalDukan/StorePayments"));
const Product = lazy(() => import("../pages/Product/ProductTabs"));
const Parties = lazy(() => import("../pages/Parties/Parties"));
const Orders = lazy(() => import("../pages/DigitalDukan/Orders"));
const Delivery = lazy(() => import("../pages/DigitalDukan/Delivery"));
const ShopCoupons = lazy(() => import("../pages/DigitalDukan/ShopCoupons"));
const ShopSocial = lazy(() => import("../pages/DigitalDukan/ShopSocial"));
const ShopTheme = lazy(() => import("../pages/DigitalDukan/ShopTheme"));
const ShopHeader = lazy(() => import("../pages/DigitalDukan/ShopHeader"));
const ShopBanners = lazy(() => import("../pages/DigitalDukan/ShopBanners"));
const StoreDashboard = lazy(
  () => import("../pages/DigitalDukan/StoreDashboard")
);
const ShopDelivery = lazy(() => import("../pages/DigitalDukan/ShopDelivery"));
const ShopBlogList = lazy(() => import("../pages/DigitalDukan/ShopBlogList"));
const ShopBlogAddEdit = lazy(
  () => import("../pages/DigitalDukan/ShopBlogAddEdit")
);
const ShopPageAddEdit = lazy(
  () => import("../pages/DigitalDukan/ShopPageAddEdit")
);
const AuthorizeRazorPay = lazy(
  () => import("../pages/DigitalDukan/AuthorizeRazorPay")
);
const ShopMenus = lazy(() => import("../pages/DigitalDukan/ShopMenus"));
const NotFound = lazy(() => import("../pages/NotFound"));

const ShopRoutes = ({ privilege }) => {
  return (
    <Routes>
      <Route path="/*" element={<DukaanLayout />}>
        <Route path="" element={<Navigate replace to="dashboard" />} />
        <Route
          path="dashboard"
          element={
            privilege.digital_dukaan === "1" ? (
              <StoreDashboard />
            ) : (
              <Unautorized />
            )
          }
        />
        <Route
          path="products"
          element={privilege.products === "1" ? <Product /> : <Unautorized />}
        />
        <Route path="orders">
          <Route
            path="shipping/:order_id"
            element={<RequestShipmentOnboarding />}
          />
          <Route
            path=""
            element={
              privilege.digital_dukaan === "1" ? <Orders /> : <Unautorized />
            }
          />
          <Route
            path="create"
            element={
              privilege.digital_dukaan === "1" ? <CreateOrder /> : <Unautorized />
            }
          />
        </Route>
        <Route path="delivery">
          <Route path="detail/:delivery_id" element={<DeliveryDetail />} />
          <Route path="" element={<Delivery />} />
        </Route>
        <Route
          path="customers"
          element={
            privilege.digital_dukaan === "1" ? <Parties /> : <Unautorized />
          }
        />
        <Route
          path="analytics"
          element={
            privilege.digital_dukaan === "1" ? (
              <ShopAnalytics />
            ) : (
              <Unautorized />
            )
          }
        />
        <Route path="coupons">
          <Route path="new" element={<CreateCoupon />} />
          <Route path="edit/:id" element={<CreateCoupon />} />
          <Route
            path=""
            element={
              privilege.digital_dukaan === "1" ? (
                <ShopCoupons title="Discount Coupons" />
              ) : (
                <Unautorized />
              )
            }
          />
        </Route>

        <Route path="appearance" element={<Outlet />}>
          <Route path="" element={<Navigate replace to="pages" />} />
          <Route path="theme" element={<ShopTheme />}>
            <Route path="" element={<Navigate replace to="headers" />} />
            <Route path="headers" element={<ShopHeader />} />
            <Route path="banners" element={<ShopBanners />} />
          </Route>
          <Route path="menus" element={<ShopMenus />} />
          <Route path="page" element={<ShopPages />} />
          <Route path="page/add" element={<ShopPageAddEdit />} />
          <Route path="page/edit/:id" element={<ShopPageAddEdit />} />
          <Route path="blog" element={<ShopBlogList />} />
          <Route path="blog/add" element={<ShopBlogAddEdit />} />
          <Route path="blog/edit/:id" element={<ShopBlogAddEdit />} />
        </Route>
        <Route path="settings" element={<ShopSettingLayout />}>
          <Route path="" element={<Navigate replace to="store-details" />} />
          <Route path="store-details" element={<StoreDetails />} />
          <Route path="domain-details" element={<DomainDetails />} />
          <Route path="payments" element={<StorePayments />} />
          <Route path="store-timings" element={<StoreTimings />} />
          <Route path="social-profile" element={<ShopSocial />} />
          <Route path="seo" element={<SeoDetails />} />
          <Route path="delivery" element={<ShopDelivery />} />
        </Route>
        <Route
          path="payments/authorize-razorpay"
          element={<AuthorizeRazorPay />}
        />
        <Route path="*" element={<NotFound path="/mydukan/dashboard" />} />
      </Route>
    </Routes>
  );
};

export default ShopRoutes;
