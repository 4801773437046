import { Worker } from "@react-pdf-viewer/core";
import { ConfigProvider, Spin, theme } from "antd";
import { useAtom } from "jotai";
import React from "react";
import { CookiesProvider } from "react-cookie";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./fonts/Inter-Bold.ttf";
import "./fonts/Inter-ExtraBold.ttf";
import "./fonts/Inter-ExtraLight.ttf";
import "./fonts/Inter-Light.ttf";
import "./fonts/Inter-Medium.ttf";
import "./fonts/Inter-Regular.ttf";
import "./fonts/Inter-SemiBold.ttf";
import ErrorComponent from "./pages/ErrorComponent";
import { AxiosInterceptor } from "./services/http.helper";
import { fullScreenLoader, themeMode } from "./storage";
import MainRoutes from "./utils/MainRoutes";
import NoInternetConnection from "./utils/NetworkStatusHook";
import ServerStatus from "./utils/ServerStatusHook";

function App() {
  const [loader] = useAtom(fullScreenLoader);
  const [theme_mode] = useAtom(themeMode);

  const theme_config = {
    token: {
      colorPrimary: "#3062E1",
      fontFamily: "'Inter', sans-serif;",
      colorBorder: "#e4e9f0",
      invoiceFooterBgColor: "#e4e3e3",
      fontSize: 13.5,
    },
    components: {
      Table: {
        cellFontSizeSM: "0.86 rem",
        cellPaddingInlineSM: 5.4,
        cellPaddingBlockSM: 9,

      },
    },
    algorithm: [theme_mode ? theme.darkAlgorithm : theme.defaultAlgorithm],
  };
  return (
    <Worker workerUrl="/js/pdf.worker.min.js">
      <ConfigProvider theme={theme_config}>
        <ErrorBoundary FallbackComponent={ErrorComponent}>
          <CookiesProvider>
            <Spin spinning={loader} fullscreen />
            <NoInternetConnection>
              <ServerStatus>
                <Router>
                  <MainRoutes />
                </Router>
              </ServerStatus>
            </NoInternetConnection>
          </CookiesProvider>
        </ErrorBoundary>
        <AxiosInterceptor />
      </ConfigProvider>
    </Worker>
  );
}

export default App;
