import { atomWithReset } from "jotai/utils";

const current = new Date();
const due = new Date(current);
due.setDate(due.getDate() + 30);

export const invoiceParty = atomWithReset({});
export const invoice = atomWithReset({
  business_id: 0,
  party_id: 0,
  invoice_number: "",
  issue_date: current,
  due_date: due,
  po_number: "",
  eway_bill_number: "",
  vehical_number: "",
  taxable_amount: 0,
  total_tax: 0,
  total_discount: 0,
  total_amount: 0,
  received_amount: 0,
  payment_mode: "Cash",
  previous_amount_balance: 0,
  type: "",
  term_condition: "",
  invoice_notes: "",
  account_holder_name: "",
  account_number: "",
  bank_name: "",
  account_type: "",
  ifsc_code: "",
  upi_id: "",
  invoice_link: "",
  AckNo: "",
  AckDt: "",
  Irn: "",
  QRCodeUrl: "",
  einvoice_pdf_link: "",
  additional_discount: 0,
  autoRoundOffChecked: true,
  roundOffAmount: 0,
  custom_fields: [],
});
export const invoiceProductList = atomWithReset([]);
export const invoiceDeleteList = atomWithReset([]);
export const chargeDeleteList = atomWithReset([]);
export const invoiceAddCharges = atomWithReset([]);
export const singleProduct = atomWithReset({
  product_name: "",
  product_sell_price: 0,
  product_gst: "0",
  product_tax: 0,
  product_tax_type: "null",
  product_description: "",
  product_code: "",
  product_hsn: "",
  discount_type: "RS",
  product_discount: 0,
  product_amount: 0,
});
export const invoiceSetting = atomWithReset({
  eway_bill_number: 0,
  show_due_date: 0,
  po_number: 0,
  show_party_balance: 0,
  vehical_number: 0,
});
export const productDummy = atomWithReset({});
export const business_data = atomWithReset({});
export const business_signature = atomWithReset("");
