import { Button, Card, Col, Form, Input, Row, Typography, message, Image, Collapse } from "antd";
import { useAtom } from "jotai";
import Lottie from "lottie-react";
import React, { lazy, useEffect, useState } from "react";
import slugify from "slugify";
import dukaan from "../../assets/animations/dukaan.json";
import onstoreImage from "../../../public/images/onlinestore1.png";
import downladApp from "../../../public/images/playstore1.png";
import billclapLogo from "../../../public/images/billclap.png";
import rightArrow from "../../assets/animations/rightArrow.json";
import { apiClient } from "../../services/http.helper";
import { business_id } from "../../storage";
import { Link } from "react-router-dom";
const CreateDukan = ({ form, getDukanDetails }) => {
  const [businessId] = useAtom(business_id);
  const [formattedDomain, setFormatedDomain] = useState("");
  const dukanName = Form.useWatch("dukaan_name", form);

  useEffect(() => {
    if (dukanName != undefined) {
      let firstName = dukanName.split(" ")[0];
      var domainName = slugify(firstName, {
        replacement: "-",
        remove: /[,*_=^%$#`<>'";{}+~.()'"!:@/?|]/g,
        lower: true,
        trim: true,
      });
      setFormatedDomain(domainName);
      form.setFieldValue("domain_name", domainName);
    }
  }, [dukanName]);

  const storeDukan = async (values) => {
    if (!(await domainStatusCheck(values.domain_name))) {
      message.error("Domain Not Available, Try another Domain!!!");
      return false;
    }
    try {
      var obj = {
        dukaan_name: values.dukaan_name,
        domain_name: formattedDomain,
        business_id: businessId,
      };
      const result = await apiClient.post("api/web/business/dukan-store", obj);
      if (result.status) {
        getDukanDetails();
        message.success("Dukan Saved Successfully!!!");
      } else {
        message.error("Error Occured", result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const domainStatusCheck = async (value) => {
    value = slugify(value, {
      replacement: "-",
      remove: /[,*_=^%$#`<>'";{}+~.()'"!:@/?|]/g,
      lower: true,
      trim: true,
    });
    setFormatedDomain(value);
    try {
      var obj = {
        domain_name: value,
        business_id: businessId,
      };
      const result = await apiClient.post("api/web/business/domain-check", obj);
      if (result.status) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const validateDomainName = async (_, value) => {
    if (value) {
      const isValid = await domainStatusCheck(value);
      if (!isValid) {
        return Promise.reject(new Error("Domain is not available!"));
      }
    }
    return Promise.resolve();
  };

  const items = [
    {
      key: '1',
      label: 'What is Billclap Dukaan?',
      children: <p>Billclap Dukaan is a comprehensive solution for businesses looking to enter the e-commerce space. With its easy setup, customization options, and robust features for managing products, orders, and marketing, it provides a convenient and effective way to run an online store. Whether you are a small business owner, a local retailer, or an entrepreneur targeting niche markets, Billclap Dukaan can help you create a professional online presence and grow your business.</p>,
    },
    {
      key: '2',
      label: 'How do I create an online store on Billclap Dukaan?',
      children: <p>To create an online store on Billclap Dukaan, sign up for an account on Billclap, choose the Dukaan option, follow the setup wizard to input your store details, customize your store's appearance, and start adding products.</p>,
    },
    {
      key: '3',
      label: 'Is there a cost to set up a store on Billclap Dukaan?',
      children: <p>Billclap Dukaan typically offers various pricing plans, including a free tier and paid tiers with more advanced features. Check the Billclap pricing page for the most current information on costs and plan features.</p>,
    },
    {
      key: '4',
      label: 'Can I customize the look and feel of my online store?',
      children: <p>Yes, you can customize the appearance of your online store by choosing from various themes, adjusting layouts, and customizing colors, fonts, and images to match your brand identity.</p>,
    },
    {
      key: '5',
      label: 'How do I manage products in my Billclap Dukaan store?',
      children: <p>You can manage products through the Billclap dashboard, where you can add new products, set prices, upload images, write descriptions, categorize products, and manage inventory levels.</p>,
    },
    {
      key: '6',
      label: 'Is Billclap Dukaan mobile-friendly?',
      children: <p>Yes, stores created with Billclap Dukaan are designed to be mobile-friendly, ensuring that customers have a smooth shopping experience on both desktop and mobile devices.</p>,
    },
    {
      key: '7',
      label: 'Can I run multiple stores on Billclap?',
      children: <p>Yes, you can run multiple stores on Billclap. This can be useful for targeting different markets or niches. Each store can be managed separately through the Billclap dashboard.</p>,
    },
    {
      key: '8',
      label: 'How secure is my data on Billclap Dukaan?',
      children: <p>Billclap prioritizes security and employs various measures to protect your data, including secure payment processing, data encryption, and compliance with relevant regulations and standards.</p>,
    },
  ];



  return (
    <>
      <div className="shop-login">
        <Row className="m-w1440 mpd">
          <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{ position: 'relative' }}>

            <Typography.Title
              level={1}
              className="fs60"
            >
              Launch your <span style={{ color: '#fe6e9a' }}>Online Dukaan</span> with <span style={{ color: '#fe6e9a' }}>0% </span>
              commissions
            </Typography.Title>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mnone">
              <Lottie
                animationData={dukaan}
                loop={true}
                style={{ maxWidth: "350px" }}
              />
            </Col>

            <span className="mnone" style={{ position: "absolute", top: "100px", right: '0' }}>
              <Lottie
                animationData={rightArrow}
                loop={true}
                style={{ width: "300px" }}
              />
            </span>


          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#fffdf2",
                boxShadow: "0 0 40px rgba(0, 0, 0, 0.16)",
                overflow: "hidden",
                margin: "0px auto",
                borderRadius: "16px",
                width: "100%",
              }}
            >
              <Card style={{ width: "100%", background: "linear-gradient(#fe6e9a, #fe8464)", borderRadius: "16px 16px 0 0" }} >
                <Row gutter={[16, 16]} justify={"space-between"}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Typography.Title
                      level={3}
                      style={{ color: "#fff", marginBottom: 0, }}
                    > My Dukaan
                    </Typography.Title>
                  </Col>
                </Row>
              </Card>

              <Row
                style={{
                  padding: "30px",
                  alignItems: "center",
                }}
                gutter={[40, 40]}
                justify={"space-between"}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form form={form} layout="vertical" onFinish={storeDukan}>
                    <Form.Item
                      label="Enter your Dukan Name: "
                      name="dukaan_name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Dukaan Name!!!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter your dukan name" size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Enter Dukan Domain: "
                      name="domain_name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Domain Name!!!",
                        },
                        {
                          validator: validateDomainName,
                        },
                      ]}
                    >
                      <Input
                        placeholder={"Enter Domain Name"}
                        suffix="billclap.in"
                        size="large"
                        prefix="https://"
                      />
                    </Form.Item>
                    <Typography.Text className="c10">https://{formattedDomain}.billclap.in</Typography.Text>
                    <Row style={{ justifyContent: "center", alignItems: "center", marginTop: '20px' }}>
                      <Button htmlType="submit" type="primary" size="large" block >
                        SUBMIT
                      </Button>
                    </Row>
                  </Form>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Lottie
                    animationData={dukaan}
                    loop={true}
                    style={{ maxWidth: "400px" }}
                  />
                </Col> */}
              </Row>
            </div>

          </Col>
        </Row>

        <Row className="mpd" style={{ backgroundColor: '#3062e1' }}>
          <div className="m-w1440" style={{ width: '100%' }}>

            <Row gutter={[16, 16]} justify={"space-between"}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography.Title
                  level={2}
                  style={{ color: "#fff", marginBottom: 0, textAlign: 'center' }}
                > Why Open Online Dukaan on Billclap
                </Typography.Title>
                <div style={{ margin: '15px auto', fontSize: '16px', color: '#fff', maxWidth: '800px', textAlign: 'center', lineHeight: '24px' }}>
                  Opening an online store (Dukaan) on Billclap can offer several advantages, especially for small and medium-sized businesses looking to establish an online presence. Here are some key reasons to consider using Billclap for your online store :
                </div>
              </Col>
            </Row>


            <Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                <Card title={<span style={{ fontSize: '22px' }}>Ease of Use</span>} bordered={false} style={{ borderRadius: '20px', fontSize: '16px', color: '#333', minHeight: '280px', textAlign: 'justify' }}>
                  Billclap provides a user-friendly interface that makes it easy to set up and manage an online store without needing extensive technical knowledge. This allows business owners to focus on their products and customers rather than on the complexities of building and maintaining a website.
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title={<span style={{ fontSize: '22px' }}>Customization</span>} bordered={false} style={{ borderRadius: '20px', fontSize: '16px', color: '#333', minHeight: '280px', textAlign: 'justify' }}>
                  Billclap allows for a high degree of customization, enabling businesses to tailor their store to their specific brand identity and customer needs. This includes customizable themes, layouts, and product displays.
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title={<span style={{ fontSize: '22px' }}>Integrated Payment Solutions</span>} bordered={false} style={{ borderRadius: '20px', fontSize: '16px', color: '#333', minHeight: '280px', textAlign: 'justify' }}>
                  Billclap offers integrated payment gateways, making it easy to accept various forms of payment from customers. This can help streamline the checkout process and improve the overall customer experience.
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title={<span style={{ fontSize: '22px' }}>Inventory Management</span>} bordered={false} style={{ borderRadius: '20px', fontSize: '16px', color: '#333', minHeight: '280px', textAlign: 'justify' }}>
                  Billclap provides robust inventory management tools that help keep track of stock levels, manage orders, and automate reordering processes. This can save time and reduce errors in inventory management.
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title={<span style={{ fontSize: '22px' }}>Customer Support</span>} bordered={false} style={{ borderRadius: '20px', fontSize: '16px', color: '#333', minHeight: '280px', textAlign: 'justify' }}>
                  Billclap offers customer support to help businesses with any issues or questions they may have. Reliable support can be crucial for resolving problems quickly and maintaining smooth operations.
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title={<span style={{ fontSize: '22px' }}>Scalability</span>} bordered={false} style={{ borderRadius: '20px', fontSize: '16px', color: '#333', minHeight: '280px', textAlign: 'justify' }}>
                  As your business grows, Billclap can scale with you. The platform can handle increased traffic, more products, and additional features as your needs evolve, ensuring that you don't outgrow your e-commerce solution.
                </Card>
              </Col>

            </Row>





          </div>
        </Row>

        <Row className="mpd">
          <div className="m-w1440" style={{ width: '100%' }}>

            <Row gutter={[16, 16]} justify={"space-between"}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography.Title
                  level={2}
                  style={{ color: "#333", marginBottom: 0, textAlign: 'center' }}
                > Some More <span style={{ color: '#fe6e9a', fontWeight: '700' }}>Online Dukaan</span> on Billclap
                </Typography.Title>
                <div style={{ margin: '15px auto', fontSize: '16px', color: '#999', maxWidth: '800px', textAlign: 'center', lineHeight: '24px' }}>
                  Expanding your presence on Billclap by opening more online stores (Dukaan) can be a strategic move for businesses looking to diversify their offerings.
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                <Link to={`https://formonix.billclap.in`} target="_blank">
                  <Card bordered={false} className="dukaan-showcase-card">
                    <img src="https://billclap-static.gumlet.io/storage/business_logo/ug3zT6PuaaUOqGRiTvNEbcGXhgrCJ2h53fAqE30u.png?w=256&q=80" alt="Fomonix" className="w-100" />
                  </Card>
                  <div className="shopName">Fomonix</div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                <Link to={`https://victoriafashion.billclap.in`} target="_blank">

                  <Card bordered={false} className="dukaan-showcase-card">
                    <img src="https://billclap-static.gumlet.io/storage/business_logo/Av0BD9NXwAxdCTCXwIdxe2lOZphbCUJOVQWpuzRT.png?w=256&q=80" alt="Victoria Fashion" className="w-100" />
                  </Card>
                  <div className="shopName">Victoria Fashion</div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                <Link to={`https://blackmoon.billclap.in/`} target="_blank">
                  <Card bordered={false} className="dukaan-showcase-card">
                    <img src="https://billclap-static.gumlet.io/storage/business_logo/h6koq547szyUwhwY4JsVyPYVwlDD4NnhSzXLLwu9.png?w=256&q=80" alt="Black Moon" className="w-100" />
                  </Card>
                  <div className="shopName">Black Moon</div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                <Link to={`https://guptastore.billclap.in/`} target="_blank">
                  <Card bordered={false} className="dukaan-showcase-card">
                    <img src="https://billclap-static.gumlet.io/storage/business_logo/mJWdHnsjDZQbD499Vy33Ldj6uDnqTr6MVrGe47kS.png?w=256&q=80" alt="Gupta Sore" className="w-100" />
                  </Card>
                  <div className="shopName">Gupta Store</div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                <Link to={`https://sharmadairy.billclap.in/`} target="_blank">
                  <Card bordered={false} className="dukaan-showcase-card">
                    <img src="https://billclap-static.gumlet.io/storage/business_logo/Y4uSXYDs3v30Iax2IitELljWPTPoGqaJUfFYlbC3.png?w=256&q=80" alt="Sharma Dairy" className="w-100" />
                  </Card>
                  <div className="shopName">Sharma Dairy</div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                <Link to={`https://sharma-vegetables.billclap.in/`} target="_blank">
                  <Card bordered={false} className="dukaan-showcase-card">
                    <img src="https://billclap-static.gumlet.io/storage/business_logo/Y4uSXYDs3v30Iax2IitELljWPTPoGqaJUfFYlbC3.png?w=256&q=80" alt="Sharma Vegetables & Fruits" className="w-100" />
                  </Card>
                  <div className="shopName">Sharma Vegetables & Fruits</div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                <Link to={`https://ergonomikal.billclap.in/`} target="_blank">
                  <Card bordered={false} className="dukaan-showcase-card">
                    <img src="https://billclap-static.gumlet.io/storage/business_logo/sd9qVENjnjlPFiMdUFy7nkyYxAmsSwP19GoUQl5l.png?w=256&q=80" alt="Ergonomikal" className="w-100" />
                  </Card>
                  <div className="shopName">Ergonomikal</div>
                </Link>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                <Link to={`https://furniture-store.billclap.in/`} target="_blank">
                  <Card bordered={false} className="dukaan-showcase-card">
                    <img src="https://billclap-static.gumlet.io/storage/shop_image/512X512/shop-img-1717136745540.jpeg?w=256&q=80" alt="Furniture Store" className="w-100" />
                  </Card>
                  <div className="shopName">Furniture Store</div>
                </Link>
              </Col>


            </Row>


          </div>
        </Row >

      </div>

      <Row className="mpd">
        <div className="m-w1440" style={{ width: '100%' }}>
          <Card style={{ maxWidth: "800px", margin: 'auto', background: "linear-gradient(#fe6e9a, #fe8464)", borderRadius: "16px", padding: '40px 10px 40px' }} >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Typography.Title
                  level={2}
                  style={{ color: "#fff", marginBottom: 0, fontSize: '45px' }}
                >Start Online Store with Billclap
                </Typography.Title>
                <Button type="primary" size={"large"} style={{ marginTop: '30px' }} >
                  Start Now
                </Button>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ textAlign: 'right' }}>
                <Image alt="online store" src={onstoreImage} width={'100%'} preview={false} />
              </Col>
            </Row>
          </Card>

        </div>
      </Row>

      <Row className="mpd" style={{ paddingTop: '0px' }}>
        <div className="m-w1440" style={{ width: '100%' }}>
          <Row gutter={[16, 16]} justify={"space-between"}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Typography.Title
                level={2}
                style={{ color: "#333", marginBottom: 0, textAlign: 'center' }}
              > Frequently Asked Questions (<span style={{ color: '#fe6e9a', fontWeight: '700' }}>FAQs</span>)
              </Typography.Title>
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Collapse size="large" items={items} defaultActiveKey={['1']} accordion />
            </Col>
          </Row>

        </div>
      </Row>

      <Row className="mpd" style={{ backgroundColor: '#3062e1' }}>
        <div className="m-w1440" style={{ width: '100%' }}>

          <Row gutter={[16, 16]} justify={"space-between"}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Typography.Title
                level={2}
                style={{ color: "#fff", marginBottom: 0, textAlign: 'center', fontSize: '40px', fontWeight: '700' }}
              > Start <span style={{ color: '#FFB4CB' }}>Online Dukaan</span> with 0% commissions
              </Typography.Title>
              <div style={{ margin: '15px auto', fontSize: '16px', color: '#fff', maxWidth: '800px', textAlign: 'center', lineHeight: '24px' }}>
                Opening an online store (Dukaan) on Billclap can offer several advantages, especially for small and medium-sized businesses looking to establish an online presence.
              </div>
            </Col>
          </Row>


          <Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
              <Button type="default" size={"large"} style={{ fontSize: '20px', fontWeight: 'bold', height: '75px', margin: '5px' }}>
                Start Online Store
              </Button>
              <Button type="link" size={"large"} style={{ margin: '5px' }}>
                <Image alt="online store" src={downladApp} width={'80'} preview={false} />
              </Button>
            </Col>
          </Row>

        </div>
      </Row>

      <Row className="mpd">
        <div className="m-w1440" style={{ width: '100%' }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
            <Image alt="billclap" src={billclapLogo} width={'140px'} preview={false} />
          </Col>
        </div>
      </Row>


    </>
  );
};

export default CreateDukan;
