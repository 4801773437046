import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { apiClient } from "../services/http.helper";

const ServerStatus = (props) => {
  // state variable holds the state of the internet connection
  const [isServerClose, setServerClose] = useState(false);

  // On initization set the isOnline state.
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const result = await apiClient.post("api/admin/server-status");

        if (result.status) {
          setServerClose(result.data?.status === "1" ? true : false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchStatus();
  }, []);

  useEffect(() => {
    const socket = io("https://api.billclap.com/");

    socket.on("serverMaintenance", (status) => {
      setServerClose(status === "1" ? true : false);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // if user is online, return the child component else return a custom component
  if (!isServerClose) {
    return props.children;
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          verticalAlign: "center",
          minHeight: "100vh",
          background:
            "radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 35%, rgba(207, 228, 233, 1) 100%)",
        }}
      >
        <div
          style={{
            width: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={4} style={{ textAlign: "center" }}>
            Server Under Maintenance Please Be Patient our Team is Looking into
            it!!!!
          </Typography.Title>
          {/* <div>
              <Lottie animationData={noInternet} loop={true} />
            </div> */}
        </div>
      </div>
    );
  }
};

export default ServerStatus;
