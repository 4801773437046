import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import * as store from "store";
import BillClapLogo from "../../../public/images/billclap.png";
import LoginBanner from "../../../public/images/login-banner.png";
import { apiClient } from "../../services/http.helper";
import { fullScreenLoader, user_access_token } from "../../storage";
import { errorNotification } from "../../utils/notification.helper";

import { IconBrandWhatsapp, IconEdit, IconPhone } from "@tabler/icons-react";
import "./login.css";

const tagManagerArgs = {
  dataLayer: {
    event: "form-lead-submit",
  },
};

const Login = ({ title }) => {
  const [secondsRemaining, setSecondsRemaining] = useState(30);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitDisabled, setSubmitDisabled] = React.useState(true);
  const [userId, setUserId] = useState(null);
  const [, setUser] = useAtom(user_access_token);
  const [buttonLoader, setButtonLoader] = useState(false);
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [, setScreenLoader] = useAtom(fullScreenLoader);
  const [otpForm] = Form.useForm();
  const [loginForm] = Form.useForm();
  const [isNewUser, setIsNewUser] = useState(false);

  // Login with mobile number
  const onLogin = async (values) => {
    try {
      setButtonLoader(true);
      var dki = window.sessionStorage.getItem("dki");
      var obj = {
        mobile: values.phoneNumber,
        source: window.sessionStorage.getItem("source") || "web",
      };
      if (dki) {
        obj.dki = dki;
      }
      const result = await apiClient.post("api/web/user/login", obj);
      if (result.status) {
        if (result.isNew) {
          setIsNewUser(true);
        }
        setIndex(1);
        setUserId(result.data.id);
        message.success("Otp Send Successfully!!!");
      }
    } catch (error) {
      errorNotification(JSON.stringify(obj), "Login", JSON.stringify(error));
      console.log(error);
    } finally {
      setButtonLoader(false);
    }
  };

  // For resend otp
  const onResendOtp = async () => {
    try {
      var obj = {
        user_id: userId,
      };
      const result = await apiClient.post("api/web/user/resend", obj);
      if (result.status) {
        setIsResendButtonDisabled(true);
        setSecondsRemaining(30);
        message.success("Otp Resend Successfully!!!");
      } else {
        message.error("Something went wrong");
      }
    } catch (error) {
      errorNotification(JSON.stringify(obj), "Login", JSON.stringify(error));
      console.log(error);
    }
  };
  const onOtpFinishFailed = (error) => {
    message.error("Please fill all fields");
  };
  const onLoginFinishFailed = () => {
    message.error("Please fill all fields");
  };
  const onValuesChange = async () => {
    try {
      setSubmitDisabled(
        !(loginForm.getFieldValue("phoneNumber").length === 10),
      );
      setPhoneNumber(loginForm.getFieldValue("phoneNumber"));
    } catch (error) {
      console.error("Error during form validation:", error);
    }
  };

  const onConfirm = async (value) => {
    try {
      setScreenLoader(true);
      var obj = {
        user_id: userId,
        otp: value.otp,
      };
      const result = await apiClient.post("api/web/user/verify", obj);
      if (result.status) {
        if (isNewUser) {
          TagManager.dataLayer(tagManagerArgs);
        }
        store.set("user_access_token", result.access_token);
        setUser(result.access_token);
        navigate("/business", { replace: true });
        message.success("Login Successfully!!!");
      } else {
        otpForm.setFields([
          {
            name: "otp",
            errors: ["Please enter valid otp"],
          },
        ]);
      }
    } catch (error) {
      errorNotification(JSON.stringify(obj), "Login", JSON.stringify(error));
      console.log(error);
    } finally {
      setScreenLoader(false);
    }
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle initialValue={"+91"}>
      <Select
        style={{
          width: 70,
        }}
      >
        <Select.Option value="+91">+91</Select.Option>
      </Select>
    </Form.Item>
  );

  // Timer code works here
  useEffect(() => {
    let countdownTimer;

    if (secondsRemaining > 0) {
      countdownTimer = setTimeout(() => {
        setSecondsRemaining((prev) => prev - 1);
      }, 1000);
    } else {
      setIsResendButtonDisabled(false);
    }

    return () => {
      clearTimeout(countdownTimer);
    };
  }, [secondsRemaining]);

  return (
    <>
      <Helmet titleTemplate="%s" title={`Login | Billclap`} />
      <div className="login-page">
        <div className="login-box">
          <div className="illustration-wrapper">
            <Image src={LoginBanner} alt="Billclap Login" preview={false} />
          </div>
          <div id="login-form">
            <div className="login-header ">
              <div className="login-header-image">
                <Image
                  src={BillClapLogo}
                  alt="Billclap Login"
                  preview={false}
                />
              </div>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  fontWeight: "bold",
                  color: "#666",
                }}
              >
                Ab Business Kro Tension Free
              </h3>
            </div>

            <div
              style={{
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              <Form
                form={loginForm}
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onLogin}
                onFinishFailed={onLoginFinishFailed}
                onValuesChange={onValuesChange}
              >
                {index === 0 && (
                  <>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input your 10-digit Phone Number!",
                          validator: (_, value) => {
                            if (/^[6789]\d{9}$/.test(value)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Please input your 10-digit Phone Number!",
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Input
                        addonBefore={prefixSelector}
                        placeholder="Input your 10-digit Phone Number"
                        style={{ width: "100%" }}
                        maxLength={10}
                        size={"large"}
                      />
                    </Form.Item>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      We will be sending an OTP to this Number
                    </p>

                    <Form.Item>
                      <Button
                        type="primary"
                        loading={buttonLoader}
                        htmlType="submit"
                        style={{ width: "100%", fontWeight: "600" }}
                        disabled={isSubmitDisabled}
                      >
                        Continue with Mobile Number
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form>

              <Form
                form={otpForm}
                name="normal_otp"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onConfirm}
                onFinishFailed={onOtpFinishFailed}
              >
                {index === 1 && (
                  <>
                    <Form.Item
                      name="otp"
                      rules={[
                        {
                          required: true,
                          message: "Please input your 4-digit OTP!",
                          pattern: /\d{4}$/,
                        },
                      ]}
                    >
                      <Input.OTP style={{ width: "100%", justifyContent: "center" }} size="large" length={4} />
                    </Form.Item>

                    <p
                      style={{ padding: 0, margin: 0 }}
                      className="d-flex align-item-center justify-content-center"
                    >
                      An OTP is sent to - {phoneNumber}
                      <Button
                        type="link"
                        className="icon-btn"
                        onClick={() => setIndex(0)}
                        icon={<IconEdit size={16} />}
                      ></Button>
                    </p>
                    <p style={{ padding: 0, margin: 0, height: "40px" }}>
                      <Button
                        onClick={() => onResendOtp()}
                        disabled={isResendButtonDisabled}
                        type="link"
                      >
                        {isResendButtonDisabled
                          ? `Timer: ${secondsRemaining}s`
                          : "Resend OTP"}
                      </Button>
                    </p>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                      >
                        Confirm OTP
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form>

              <p
                style={{
                  fontSize: "12px",
                  color: "#333",
                  lineHeight: "20px",
                  fontWeight: "500",
                }}
              >
                By continuing you agree to our{" "}
                <a
                  href="https://www.billclap.com/terms"
                  className="c10"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Terms
                </a>{" "}
                &{" "}
                <a
                  href="https://www.billclap.com/privacy-policy"
                  className="c10"
                  target="_blank"
                  rel="noreferrer"
                >
                  Policy
                </a>
              </p>
              <Divider></Divider>
              <Typography.Text
                style={{ fontSize: "14px", color: "#333", fontWeight: "600" }}
              >
                For Help/Support
              </Typography.Text>
              <Space
                direction="vertical"
                style={{
                  display: "flex",
                  marginTop: "10px",
                  fontSize: "16px",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "500",
                }}
              >
                <a
                  href="tel:08929003309"
                  className="c10 d-flex align-item-center"
                >
                  <IconPhone size={16} /> +91-8929003309
                </a>
                <a
                  href="https://wa.me/917011635836?text=Hi, I need some help regarding billclap"
                  style={{ color: "green" }}
                  target="_blank"
                  rel="noreferrer"
                  className="d-flex align-item-center"
                >
                  <IconBrandWhatsapp size={16} /> +91-8929003309
                </a>
              </Space>
              <p
                style={{
                  fontSize: "9px",
                  color: "#666",
                  fontWeight: "500",
                  paddingTop: "20px",
                }}
              >
                &copy; {dayjs().year()} Digiclap Technologies Private Limited.
                All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
