import { Spin, message } from "antd";
import { useAtom } from "jotai";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import Login from "../pages/login/Login";
import MainLayout from "../layout/MainLayout";
import { apiClient } from "../services/http.helper";
import {
  business_id,
  paidUser,
  sub_user,
  tabsPrivilege,
  user_access_token,
} from "../storage";
import { PremiumFeature } from "./ModalHelper";
import { AuthProtectedRoute, BusinessProtectedRoute } from "./ProtectedRoute";
import ShopRoutes from "./ShopRoutes";
import { errorNotification } from "./notification.helper";

const InvoicePreview = lazy(() => import("../pages/InvoicePreview"));
const Unautorized = lazy(() => import("../pages/Unautorized"));
const NotFound = lazy(() => import("../pages/NotFound"));
const Authenticate = lazy(() => import("../pages/Authenticate"));
const InvoiceDetails = lazy(() => import("../pages/sales/InvoiceDetails"));
const Reports = lazy(() => import("../pages/Reports/Reports"));
const ReportDetail = lazy(() => import("../pages/Reports/ReportDetail"));
const Einvoicing = lazy(() => import("../pages/Einvoicing/EinvoiceListing"));
const BusinessOnboarding = lazy(
  () => import("../pages/Onboarding/BusinessOnboarding"),
);
const Businesslist = lazy(() => import("../pages/Onboarding/Businesslist"));
const Createaccountgst = lazy(
  () => import("../pages/Onboarding/Createaccountgst"),
);
const PartyDetail = lazy(() => import("../pages/Parties/PartyDetail"));
const PricingNormal = lazy(() => import("../pages/PricingNormal"));
const PricingSale = lazy(() => import("../pages/PricingSale"));
const Bahikhata = lazy(() => import("../pages/Bahikhata/Bahikhata"));

const Expenses = lazy(() => import("../pages/Expenses/Expenses"));
const Pos = lazy(() => import("../pages/Pos/Pos"));
const Parties = lazy(() => import("../pages/Parties/Parties"));
const Payment = lazy(() => import("../pages/Payment.js/Payment"));
const Settings = lazy(() => import("../pages/Setting/Settings"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Warehouse = lazy(() => import("../pages/Product/Inventory/Warehouse"));
const Product = lazy(() => import("../pages/Product/ProductTabs"));
const CreateInvoice = lazy(() => import("../pages/sales/CreateInvoice"));
const Invoice = lazy(() => import("../pages/sales/InvoiceLayout"));
const Invitation = lazy(() => import("../pages/Invitation"));
const CreateInvoiceValidator = () => {
  const { action, invoice_id } = useParams();
  const isValidAction =
    action === "create" || action === "edit" || action === "convert";
  if (!isValidAction) {
    // Redirect to an error page or handle the situation accordingly
    return <NotFound />;
  }
  if (action !== "create") {
    if (!invoice_id || isNaN(invoice_id)) {
      return <NotFound />;
    }
  }

  return <CreateInvoice />;
};

const OnboardingValidator = ({ userId, businessId }) => {
  if (businessId) {
    // Redirect to an error page or handle the situation accordingly
    return <BusinessOnboarding />;
  } else {
    if (userId) {
      <Navigate to={"/business"} replace />;
    } else {
      <Navigate to={"/login"} replace />;
    }
  }
};

const SettingValidator = ({ subUser }) => {
  if (subUser) {
    return <Navigate to={"/"} replace />;
  } else {
    return <Settings />;
  }
};

const LoginValidator = ({ userId, businessId }) => {
  if (businessId && userId) {
    return <Navigate to={"/"} replace />;
  } else {
    if (userId) {
      return <Navigate to={"/business"} replace />;
    } else {
      return <Login title={"Login"} />;
    }
  }
};

const BusinessValidator = () => {
  const [businessData, setBusinessData] = useState(<Spin fullscreen></Spin>);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiClient.get("api/web/business/");
        if (result.status) {
          if (result.data.length > 0 || result.sub_user_data.length > 0) {
            setBusinessData(<Businesslist />);
          } else {
            setBusinessData(<Createaccountgst />);
          }
        } else {
          message.error("Error Occured", result.message);
        }
      } catch (error) {
        setError(error);
        errorNotification(
          JSON.stringify(),
          "MainRoutes",
          JSON.stringify(error),
        );
      }
    };

    fetchData();
  }, []);

  if (error) {
    console.log("error : ", error);
    return <NotFound />;
  }

  return businessData;
};

const PaidCheckValidator = ({ output }) => {
  const [isPaid] = useAtom(paidUser);

  if (!isPaid) {
    return <PremiumFeature />;
  }
  return output;
};

const PrivilegeValidator = ({ children, privilege }) => {
  const { invoice_type } = useParams();
  if (
    invoice_type === "invoices" ||
    invoice_type === "credit-note" ||
    invoice_type === "quotations" ||
    invoice_type === "delivery-challan"
  ) {
    if (privilege.sales === "1") {
      return children;
    } else {
      return <Unautorized />;
    }
  } else {
    if (privilege.purchases === "1") {
      return children;
    } else {
      return <Unautorized />;
    }
  }
};

const MainRoutes = () => {
  const [user] = useAtom(user_access_token);
  const [businessId] = useAtom(business_id);
  const [subUser] = useAtom(sub_user);
  const [privilege] = useAtom(tabsPrivilege);
  const [pricePage, setPricePage] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        const result = await apiClient.get("api/web/business/fetchPriceData");
        if (result.status && result.pricePage.length) {
          setPricePage(result.pricePage[0].selected_page);
        }
      } catch (error) {
        setError(error);
        errorNotification(
          JSON.stringify(),
          "MainRoutes",
          JSON.stringify(error),
        );
      }
    };

    fetchPriceData();
  }, []);

  return (
    <Routes>
      <Route
        element={<AuthProtectedRoute user={user} businessId={businessId} />}
      >
        <Route element={<BusinessProtectedRoute businessId={businessId} />}>
          <Route
            path="/onboarding"
            element={
              <Suspense fallback={<Spin />}>
                <OnboardingValidator userId={user} businessId={businessId} />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense fallback={<Spin />}><MainLayout />
              </Suspense>
            }
          >
            <Route path="/" element={<Navigate replace to="dashboard" />} />

            <Route
              path="dashboard"
              element={
                privilege.dashboard === "1" ? <Dashboard /> : <Unautorized />
              }
            />
            <Route path="/document/:invoice_type">
              <Route
                path="details/:invoice_id"
                element={
                  <PrivilegeValidator
                    children={<InvoiceDetails />}
                    privilege={privilege}
                  />
                }
              />

              <Route
                index
                element={
                  <PrivilegeValidator
                    children={<Invoice />}
                    privilege={privilege}
                  />
                }
              />
            </Route>

            <Route
              path="product"
              element={
                privilege.products === "1" ? <Product /> : <Unautorized />
              }
            />
            <Route
              path="inventory"
              element={
                privilege.products === "1" ? <Warehouse /> : <Unautorized />
              }
            />

            <Route path="/parties">
              <Route
                path=""
                element={
                  privilege.parties === "1" ? <Parties /> : <Unautorized />
                }
              />
              <Route
                path="details/:party_id"
                element={
                  privilege.parties === "1" ? <PartyDetail /> : <Unautorized />
                }
              />
            </Route>
            <Route
              path="expenses"
              element={
                privilege.expenses === "1" ? <Expenses /> : <Unautorized />
              }
            />

            <Route path="pricing" element={pricePage == 'Normal Day' ? <PricingNormal /> : <PricingSale />} />

            <Route
              path="settings"
              element={<SettingValidator subUser={subUser} />}
            />

            <Route
              path="payment"
              element={
                privilege.payment === "1" ? <Payment /> : <Unautorized />
              }
            />

            <Route
              path="pos"
              element={privilege.pos === "1" ? <Pos /> : <Unautorized />}
            />

            <Route
              path="bahikhata"
              element={
                privilege.udhaar_khata === "1" ? <Bahikhata /> : <Unautorized />
              }
            />
            <Route path="/reports">
              <Route
                path="/reports"
                element={
                  privilege.reports === "1" ? (
                    <PaidCheckValidator output={<Reports />} />
                  ) : (
                    <Unautorized />
                  )
                }
              />
              <Route
                path=":report_type"
                element={
                  privilege.reports === "1" ? (
                    <PaidCheckValidator output={<ReportDetail />} />
                  ) : (
                    <Unautorized />
                  )
                }
              />
            </Route>

            <Route
              path="einvoicing"
              element={
                privilege.einvoicing === "1" ? (
                  <PaidCheckValidator output={<Einvoicing />} />
                ) : (
                  <Unautorized />
                )
              }
            />
          </Route>
          <Route path="/document/:invoice_type">
            <Route
              path=":action/:invoice_id?"
              element={
                <PrivilegeValidator
                  children={<Suspense fallback={<Spin />}><CreateInvoiceValidator /></Suspense>}
                  privilege={privilege}
                />
              }
            />

          </Route>
          <Route
            path="/mydukan/*"
            element={<ShopRoutes privilege={privilege} />}
          />
        </Route>
        <Route
          path="/business"
          element={<BusinessValidator userId={user} businessId={businessId} />}
        />
      </Route>
      <Route
        path="/login"
        element={<LoginValidator userId={user} businessId={businessId} />}
      />
      <Route path="/admin/authenticate" element={<Authenticate />} />
      <Route path="/invitation/:inviteid/:token/" element={<Invitation />} />
      <Route path="/preview/:invoiceid/:token/" element={<InvoicePreview />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default MainRoutes;
