import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import store from "store";
import NotFound from "../pages/NotFound";
import CreateInvoice from "../pages/sales/CreateInvoice";
import { apiClient } from "../services/http.helper";
import { errorNotification } from "./notification.helper";

const useHasBusiness = () => {
  const [hasBusiness, setHasBusiness] = useState(null);

  useEffect(() => {
    const checkHasBusiness = async () => {
      try {
        const result = await apiClient.get("api/web/business/");
        if (result.status) {
          if (result.data.length > 0 || result.sub_user_data.length > 0) {
            setHasBusiness(true);
          } else {
            setHasBusiness(false);
          }
        } else {
          message.error("Error Occured", result.message);
        }
      } catch (error) {
        errorNotification(
          JSON.stringify(),
          "MainRoutes",
          JSON.stringify(error),
        );
      }
    };
    checkHasBusiness();
  }, []);
  return hasBusiness;
};

export const AuthProtectedRoute = ({ user }) => {
  if (!user) {
    store.clearAll();
    return <Navigate to={"/login"} replace />;
  } else {
    return <Outlet />;
  }
};

export const BusinessCheckProtectedRoute = () => {
  const hasBusiness = useHasBusiness();
  if (hasBusiness === null) {
    return <Spin fullscreen></Spin>;
  }
  if (hasBusiness === false) {
    return <Navigate to={"/createaccountgst"} replace />;
  } else {
    return <Outlet />;
  }
};

export const BusinessProtectedRoute = ({ businessId }) => {
  if (!businessId) {
    return <Navigate to={"/business"} replace />;
  } else {
    return <Outlet />;
  }
};

export const CreateInvoiceRouteValidator = () => {
  const params = useParams();
  if (params.action === "create" || params.action === "convert") {
    return <CreateInvoice />;
  } else {
    return <NotFound />;
  }
};
