import {
  IconBrandGoogleAnalytics,
  IconChartInfographic,
  IconChevronLeft,
  IconClipboardText,
  IconEdit,
  IconMenu2,
  IconPackageImport,
  IconPalette,
  IconRosetteDiscount,
  IconSettings,
  IconShare3,
  IconUsers,
} from "@tabler/icons-react";

import {
  Button,
  Drawer,
  Form,
  Grid,
  Layout,
  Menu,
  Space,
  Spin,
  Typography,
  theme,
} from "antd";
import { useAtom } from "jotai";
import React, { Suspense, useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import CreateDukan from "../pages/DigitalDukan/CreateDukan";
import DukanNameModal from "../pages/DigitalDukan/DukanNameModal";
import "../pages/DigitalDukan/dukan.css";
import { apiClient } from "../services/http.helper";
import { business_id } from "../storage";
import { isScreenSizeConditionMet } from "../utils/MobileViewHelper";
import { errorNotification } from "../utils/notification.helper";
const { Header, Sider, Content } = Layout;
const { useBreakpoint } = Grid;
const DukaanLayout = ({ props }) => {
  const [dukanDetails, setDukanDetails] = useState(null);
  const [fcpurl, setFcpurl] = useState("");
  const [nameModal, setNameModal] = useState(false);
  const [businessId] = useAtom(business_id);
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const [open, setOpen] = useState(false);
  const isMobileView = isScreenSizeConditionMet();

  const [menuState, setMenuState] = useState({
    collapsed: false,
    layoutWidth: 0,
    transition: "all 0.4s",
  });
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const location = useLocation();
  const createHeaderTitle = (key) => {
    var str = key[0].toUpperCase() + key.slice(1);
    return str;
  };
  const fetchDefaultHeader = () => {
    var key =
      location.pathname.split("/")[location.pathname.split("/").length - 1] ||
      "dashboard";
    return createHeaderTitle(key);
  };
  const [headerTitle, setHeaderTitle] = useState(fetchDefaultHeader());

  const handleOpenModal = () => {
    setNameModal(!nameModal);
  };

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };
  const getDukanDetails = async () => {
    const obj = {
      business_id: businessId,
    };
    try {
      const result = await apiClient.post("api/web/business/dukan-detail", obj);
      if (result.status) {
        form.setFieldValue("dukaan_name", result.data.dukan_name);
        setDukanDetails(result.data);
        setFcpurl(result.data.fcp_url);
      } else {
        setDukanDetails({});
      }
    } catch (error) {
      errorNotification(
        JSON.stringify(obj),
        "My Dukaan",
        JSON.stringify(error)
      );
    }
  };
  const onClose = () => {
    setOpen(false);
  };
  const menuCollapsed = () => {
    if (menuState.collapsed) {
      setMenuState({
        ...menuState,
        collapsed: false,
        layoutWidth: 250,
        transition: "all 0.1s",
      });
    } else {
      setMenuState({
        ...menuState,
        collapsed: true,
        layoutWidth: 80,
        transition: "all 0.4s",
      });
    }
  };
  const handleOpenDrawer = () => {
    if (isMobileView) {
      setOpen(!open);
    } else {
      menuCollapsed();
    }
  };
  useEffect(() => {
    getDukanDetails();
  }, []);

  const sideitems = [
    getItem(
      <NavLink to="/mydukan/dashboard">Dashboard</NavLink>,
      "dashboard",
      <IconChartInfographic />
    ),
    getItem(
      <NavLink to="/mydukan/products">Product</NavLink>,
      "products",
      <IconPackageImport />
    ),
    getItem(
      <NavLink to="/mydukan/orders">Orders</NavLink>,
      "orders",
      <IconClipboardText />
    ),
    getItem(
      <NavLink to="/mydukan/delivery">Delivery</NavLink>,
      "delivery",
      <IconClipboardText />,
    ),
    getItem(
      <NavLink to="/mydukan/analytics">Analytics</NavLink>,
      "analytics",
      <IconBrandGoogleAnalytics />
    ),
    getItem(
      <NavLink to="/mydukan/customers">Audience</NavLink>,
      "customers",
      <IconUsers />
    ),
    getItem(
      <NavLink to="/mydukan/coupons">Discounts</NavLink>,
      "coupons",
      <IconRosetteDiscount />
    ),
    getItem("Appearance", "appearance", <IconPalette />, [
      getItem(<NavLink to="/mydukan/appearance/theme">Theme</NavLink>, "theme"),
      getItem(<NavLink to="/mydukan/appearance/page">Pages</NavLink>, "pages"),
      getItem(<NavLink to="/mydukan/appearance/menus">Menus</NavLink>, "menus"),
      getItem(<NavLink to="/mydukan/appearance/blog">Blogs</NavLink>, "blogs"),
    ]),
    getItem(
      <NavLink to="/mydukan/settings">Settings</NavLink>,
      "settings",
      <IconSettings />
    ),
  ];

  const handleMenuChange = (key) => {
    setHeaderTitle(createHeaderTitle(key));
  };

  if (!dukanDetails) {
    return (
      <Suspense>
        <Spin></Spin>
      </Suspense>
    );
  }

  return (
    <>
      {dukanDetails.sub_domain_name ? (
        <>
          <Layout>
            <Sider
              theme="dark"
              trigger={null}
              collapsible
              collapsed={menuState.collapsed}
              width={250}
              className={["site-sidebar", "hideOnMobile"]}
              style={{
                overflow: "auto",
                height: "100vh",
                position: "sticky",
                top: 0,
                left: 0,
                padding: 10,
              }}
            >
              <div className="menu-logo">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-item-center">
                    <Link to={"/"}>
                      <IconChevronLeft style={{ color: "white" }} />
                    </Link>
                    <div
                      className="business-name"
                      onClick={handleOpenModal}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <span
                        style={{
                          width: "100px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {dukanDetails?.dukan_name}
                      </span>
                      <IconEdit size={16} style={{ color: "white" }} />
                    </div>
                  </div>
                  <div>
                    <Link
                      to={`https://${dukanDetails.sub_domain_name}.billclap.in`}
                      target="_blank"
                    >
                      <IconShare3 />
                    </Link>
                  </div>
                </div>
              </div>
              <Menu
                mode="inline"
                theme="dark"
                triggerSubMenuAction="hover"
                defaultOpenKeys={[
                  location.pathname.split("/")[
                    location.pathname.split("/").length - 2
                  ] || "dashboard",
                ]}
                defaultSelectedKeys={[
                  location.pathname.split("/").pop() || "dashboard",
                ]}
                className="main-menu"
                items={sideitems}
                onSelect={(value) => handleMenuChange(value.key)}
              />
            </Sider>
            <Layout className="site-layout">
              <Header
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  alignItems: "center",
                  padding: 0,
                  background: colorBgContainer,
                }}
              >
                <div
                  className="d-flex justify-content-between align-item-center"
                  style={{ height: "100%", margin: "auto 10px" }}
                >
                  <div className="d-flex justify-content-between align-item-center">
                    <Space
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      direction="horizontal"
                    >
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={handleOpenDrawer}
                        icon={<IconMenu2 />}
                      />
                      <Typography.Text
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        {headerTitle}
                      </Typography.Text>
                    </Space>
                  </div>
                </div>
              </Header>
              <Content
                style={{
                  margin: "24px 16px",
                  padding: "10px 16px",
                  background: colorBgContainer,
                }}
              >
                <Suspense fallback={<Spin size="large" />}>
                  <Outlet />
                </Suspense>
              </Content>
            </Layout>
            {(screens.sm && !screens.md) || screens.xs ? (
              <Drawer
                width={250}
                placement="left"
                onClose={onClose}
                open={open}
                closable={true}
                maskClosable={true}
                className="hideOnDesktop"
                key="right"
                styles={{
                  body: {
                    padding: 0,
                  },
                }}
              >
                <Menu
                  theme="dark"
                  mode="inline"
                  defaultOpenKeys={[
                    location.pathname.split("/")[0] || "dashboard",
                  ]}
                  defaultSelectedKeys={[
                    location.pathname.split("/").pop() || "dashboard",
                  ]}
                  onClick={() => setOpen(false)}
                  items={sideitems}
                />
              </Drawer>
            ) : (
              ""
            )}
          </Layout>
          <DukanNameModal
            open={nameModal}
            businessDetails={dukanDetails}
            handleChange={handleOpenModal}
            fetchDetails={getDukanDetails}
          />
        </>
      ) : (
        <Suspense fallback={<Spin size="large" />}>
          <CreateDukan form={form} getDukanDetails={getDukanDetails} />
        </Suspense>
      )}
    </>
  );
};

export default DukaanLayout;
